import { ethers, JsonRpcProvider, Contract } from "ethers";
import abi from "./token.json";

export async function Allowance(tokenAddress, saleAddress, address) {
  const provider = new JsonRpcProvider("https://bsc-dataseed1.binance.org/");

  const contract = new ethers.Contract(tokenAddress, abi, provider);
  const allowance = await contract.allowance(address, saleAddress);
  // console.log("ALLOWANCE", allowance.toString());
  return allowance.toString();
}
